import about from "./languages/pages/about";
import aml from "./languages/pages/aml";
import banking from "./languages/pages/banking";
import bitcoin from "./languages/pages/bitcoin";
import bonus from "./languages/pages/bonus";
import cookie from "./languages/pages/cookie";
import faq from "./languages/pages/faq";
import privacy from "./languages/pages/privacy";
import responsible from "./languages/pages/responsible";
import security from "./languages/pages/security";
import support from "./languages/pages/support";
import terms from "./languages/pages/terms";
import vip from "./languages/pages/vip";
import casino from "./config";
import React from "react";

const untilWeDontHaveOther = {
    title: "INTRODUCTION",
    content: [<>
        <ul style={{listStyle: 'none'}}>
            <li>1.1. Playing at the casino, whether for “fun play” or “real play” constitutes the member’s (player)
                agreement to be bound by the following terms and conditions contained herein. The player accepts that
                this is a binding agreement between the player and the casino and is the sole agreement governing the
                player’s relationship with the casino, to the exclusion of all other agreements, statements, and
                representations.
            </li>

            <li>1.2. Each clause contained in these terms and conditions shall be separate and severable from each of
                the others. If any clause is found to be void, invalid, or unenforceable for any reason, the remaining
                terms and conditions shall remain of full force and effect.
            </li>
            <li>1.3. The Company reserves the right of final decision on the interpretation of these Terms and
                Conditions. Email and chat services may provide information and updates about our service. Communication
                by email or chat cannot modify or revoke the Terms and Conditions. Failure on chat or email to cover our
                Terms and Conditions or to mention a portion of our Terms and Conditions does not constitute grounds for
                violating the Terms and Conditions. In case of dispute, the Terms and Conditions hold final authority
                and will be enforced over any information provided over chat or email. All players are required to read
                the Terms and Conditions prior to registration.
            </li>
            <li>1.4. The following Terms and Conditions govern the use of the {casino.name} website and online casino:
            </li>
            <li>1.5. IMPORTANT: All deposits are final. No refund can be completed once the deposits have been played at
                our games.
            </li>
        </ul>
    </>],

    sections: [{
        title: "WEBSITE AND CASINO CONTENT",
        content: [<>
            <ul style={{listStyle: 'none'}}>
                <li>2.1. All software and website content found on the {casino.name} Casino website is copyrighted by
                    the
                    Casino (the “Licensed Materials”). All Licensed Material that are presented visually or audibly to
                    the player are for personal use only and remain the intellectual property of the Casino and its
                    licensees. No player shall acquire any right whatsoever to all or any part of the Licensed Materials
                    except the right to use the Licensed Materials in accordance with this Agreement.
                </li>
            </ul>
        </>]

    },
        {
            title: "PLAYER OBLIGATION",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>3.1. In becoming a member (player) of the casino, you fully understand and agree to abide by all
                        rules, terms and conditions set forth by {casino.name} from time to time. It is the player’s
                        responsibility to keep up to date with these rules, terms and conditions as they are subject to
                        periodic change.
                    </li>
                    <li>3.2. Playing at {casino.name} Online Casino is restricted to adults only. Players must be 25
                        years
                        of age or older to play. Age restrictions may change according to local national law, and it is
                        the responsibility of the player to check for the minimum age allowed in their country. The
                        Casino reserves the right to require age verification from the player at any time.
                    </li>
                    <li>3.3. Players from the following countries may not be allowed to open an account at our casino:
                        Afghanistan, Armenia, Azerbaijan, Bangladesh, Belarus, Bhutan, Bulgaria, Cayman Islands, Costa
                        Rica, Denmark, Estonia, Hungary, Kazakhstan, Kyrgyzstan, Netherlands, Netherlands Antilles,
                        Poland, Romania, Singapore, Syria, Turkmenistan, and Virgin Islands. Accounts will be closed and
                        all game winnings or losses voided.
                    </li>
                    <li>3.4. In creating an account and becoming a player at the casino, whether for “fun play” or “real
                        play”, you guarantee the authenticity and accuracy of all information you submit. If it is
                        determined by {casino.name} that any information it was given by the player is
                        false, {casino.name}
                        reserves the right to suspend a player’s account along with all related cash-ins. The account
                        will remain suspended until such time as {casino.name} has determined in its sole discretion
                        that
                        the player’s information was authentic and accurate.
                    </li>
                    <li>3.5. {casino.name} reserves the right to refuse membership in the casino to any applicant or to
                        suspend an existing member’s playing privileges without cause and at {casino.name}’s sole
                        discretion.
                    </li>
                    <li>3.6. In placing wagers at the casino, the player realizes that this is their voluntary
                        involvement and is equal to gambling. Participation carries the risk of losing money and
                        {casino.name} shall under no circumstances be liable for any losses or damages whatsoever
                        incurred
                        by the Player in connection with the player’s use of the casino.
                    </li>
                    <li>3.7. Any deposit made to the casino must be wagered once before a withdrawal can be requested.
                        If deposit is not wagered at least once before withdrawal, the casino reserves the right to
                        charge the player for any processing fees.
                    </li>
                    <li>3.8. Using electronic, mechanical, robotic or other devices to automatically make playing
                        decisions is resolutely forbidden. If it is detected by {casino.name} that a player has violated
                        this term, action may and will be taken including banning the player from future promotions
                        and/or play, confiscating of net winnings, and the offering of such information to a casino
                        industry database of “restricted players”.
                    </li>
                    <li>3.9. By playing any game at the casino, the player warrants that the player is familiar with the
                        rules of such a game and acknowledges that the rules of each game are available through using
                        the “help” facility at the casino.
                    </li>
                    <li>3.10. When depositing with debit/credit card, additional third-party costs may be applied by the
                        Player's credit card company and/or the card associations and as such are beyond the Company's
                        control. Please note that you may be charged an additional foreign transaction fee per
                        transaction depending on the deposited amount by the credit card processing banks. Usually,
                        foreign transaction fees are a percentage of the amount of each foreign currency purchase, with
                        no minimum or maximum, reaching even 6-8% at times. These are fees charged by the transaction
                        processors such as VISA and MC. Most issuing banks choose to pass this fee along to their
                        customer.
                    </li>
                    <li>3.11. The casino reserves the right to request a Security Verification Form at any time. If a
                        person does not return a faxback form and the required personal identification within 30 days of
                        such request, risks suspension and possible forfeiture of his/her account. In such a case, the
                        casino may unilaterally determine resolution of any account funds.
                    </li>
                    <li>3.12. When making a Bitcoin (BTC) deposit, the player is solely responsible for correctly
                        transferring the bitcoins to the address provided by the cashier. To ensure every Bitcoin
                        transfer is credited automatically, the player must initiate the Bitcoin deposit process in the
                        cashier for EVERY Bitcoin deposit to be made. We do not process or accept any other
                        cryptocurrency and cannot credit player accounts for funds sent through other cryptocurrencies;
                        for example, if a player transfers Bitcoin Cash (BCH), we cannot credit the player account for
                        that cryptocurrency.
                    </li>
                </ul>
            </>]

        },
        {
            title: "ACCOUNTS & PASSWORDS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>4.1. {casino.name} permits only one casino account per person, household, IP address, email
                        address
                        or computer at each of its casinos. Having a linked account at the casino may cause your account
                        to be closed and any winnings forfeited.
                    </li>
                    <li>4.2. Due to an overwhelming history of promotional abuse, players from certain countries are
                        forced to abide by separate wagering requirements for offered promotions. These can be found in
                        our “Promotions terms and conditions”.
                    </li>
                    <li>4.3. It is the responsibility of the player to keep confidential their account number and
                        password. It is understood that having any third party involved in a player’s online casino
                        account is a violation of this agreement. The player assumes full responsibility and agrees not
                        to hold {casino.name} liable for any losses or damages resulting from the player’s failure to
                        keep
                        his account number and password confidential.
                    </li>
                    <li>4.4. In providing their email address upon registration, the player agrees to receive occasional
                        email correspondence at that address. If the player is no longer interested in receiving these
                        emails, they may unsubscribe by emailing support.
                    </li>
                    <li>4.5. By providing their postal address, the player agrees to receive occasional hardcopy
                        material from the casino. If the player is no longer interested in receiving mail, they may
                        unsubscribe by emailing support.
                    </li>
                </ul>
            </>]

        },
        {
            title: "GENERAL TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>5.1. The Casino is committed to offering fair and honest games and confirms the software and all
                        games offered by {casino.name} Online Casino rely on an audited and validated Random Number
                        Generator (RNG) by a third party ensuring that all games are fair and honest.
                    </li>
                    <li>5.2. The Casino reserves the right to modify the balance on a user's account to redeem sums owed
                        to the Casino.
                    </li>
                    <li>5.3. The user agrees to pay all sums due to {casino.name} Online Casino and to never try to
                        re-attribute, deny, revoke, dispute, chargeback, block, hold or cancel any of these payments.
                    </li>
                    <li>5.4. If the user has deposited or withdrawn funds using an E-Wallet such as MoneyBookers,
                        Neteller or EcoCard, the Casino may request copies of other transactions showing that specific
                        E-Wallet in use.
                    </li>
                    <li>5.5. Any illegal or criminal activity made by the player will result in immediate termination of
                        the Casino account and the Casino reserves the right to inform the proper authorities and other
                        online casinos and merchants of these actions with full disclosure.
                    </li>
                    <li>5.6. The Casino reserves the right to change any of its offered services at any time, including
                        a temporary unavailability of customer support, technical support or any other services given to
                        the player.
                    </li>
                    <li>5.7. In the event of technical failure or computer glitch, all bets in play may be considered
                        null and void. The Casino cannot be held responsible for losses, wins or other issues generated
                        by a technical failure or glitch in any way or form.
                    </li>
                    <li>5.8. Should a player abstain from logging into their Casino account for a period of 90 days or
                        more or the player 90 days without making a single bet or wager, the Casino will be entitled to
                        any balance in that player's account and the sum forfeit. Should the player wish to withdraw
                        this amount after this period, we will examine each situation on a case by case basis but
                        reserve the right to invoke the above 3 month rule.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PROMOTIONS AND BONUS TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>6.1. All and any free bonuses and deposit bonuses cannot be withdrawn from the Casino, and are
                        meant to enhance game time only. Upon withdrawal, the bonus amounts will be deducted by the
                        Casino before the withdrawal is sent.
                    </li>
                    <li>6.2. All bonus money will be removed at the point of cash out, bonus money is money that can be
                        used to help generate winnings but cannot be withdrawn as part of the winnings. The bonus will
                        be treated as 'Non-Withdrawable' and will not be reissued to your account but deducted from the
                        winnings by the casinos withdrawal manager during the payout process. Cashback money is also
                        'Non-Withdrawable' and the casino reserves the right to deduct cashback money from a players'
                        pending withdrawal.
                    </li>
                    <li>6.3. Bonuses, unless specifically specified by the Casino beforehand, carry a wagering
                        requirement that must be completed before a player can withdraw.
                    </li>
                    <li>6.4. All cashback promotions carry a wagering requirement of 1X. Players cannot withdraw a
                        cashback bonus until the sum of the cashback is wagered in full. All cashback money is
                        restricted to a maximum withdrawal limit of 10X the cashback amount. This includes cashback
                        awarded as 'gifts', 'comps' or 'free chips'. The Casino reserves the right to deduct Cashback
                        money from a pending withdrawal
                    </li>
                    <li>6.5. All cashback money won from a Tournament prize, Raffle prize or Special Promotion have a
                        maximum withdrawal limit of 10X the cashback bonus and carry a wagering requirement of 1X. All
                        bonuses of 200% or more, cashback insurance of 75% or more will be considered Special
                        Promotions. The Casino reserves the right to deduct Cashback money from a pending withdrawal.
                        Players who have a pending withdrawal or balance of over 100 $/€/£/ do not qualify to receive
                        Tournament prizes or to receive cash from a Raffle or cashback from any ongoing Cashback
                        promotion or any other special promotion offered by the Promotions Team.
                    </li>
                    <li>6.5.1 Special Bonus - all bonuses of 200% or more, cashback bonuses of 100% or more will be
                        considered Special Bonuses
                    </li>
                    <li>6.6. It is the responsibility of the player to be aware of the details of the bonus they request
                        or is given to them, including the wagering requirement, restricted games and any other details,
                        terms and requirements associated with said bonus.
                    </li>
                    <li>6.7. Players choosing not to play with bonuses, must state so before starting to play with a
                        bonus. If a player does not want the bonus that has been deposited into their account by the
                        casino, then they are entitled to request a casino customer support representative reverse the
                        bonus out of their casino account, by way of email, telephone call or live Chat. If a bonus is
                        received and a wager is made (of any amount), the bonus will have to be played all the way
                        through, until the balance is zero or the wagering requirement is complete. If the player has
                        not begun to play, support will remove the bonus should they ask for it.
                    </li>
                    <li>6.8. Free bonuses (Comps, Free Chips, converted Comp Points or any other non-deposit bonuses)
                        are playable bonuses awarded to players so they may play free of charge. Free bonuses have a
                        different wagering requirement than other bonuses, and have a fixed withdrawal limit of no more
                        than 50 Credits (50 $,€,£,AUD, or 500 ZAR). Any remaining or extra balance is
                        'Non-Withdrawable' and is to be cleared from a player's balance. Each player is allowed to
                        withdraw winnings from a free bonus only once during his/her gaming lifetime unless specifically
                        approved by the casino manager.
                    </li>
                    <li>6.9. A player who has never made a deposit and wishes to withdraw a free chip win, is required
                        to first make one successful minimum deposit of 15.00 ($,€,£,AUD, or 150 ZAR). This first
                        deposit amount is not allowed to be withdrawn or added to an existing pending withdrawal. If
                        added to an existing withdrawal, the amount will be deducted from the total withdrawal amount. A
                        player who has previously made a successful deposit and is now wishing to withdraw a free chip
                        win, is required to have deposited within the last 30 days in order for the withdrawal to be
                        approved and the deposit cannot be added to an existing pending withdrawal. If added to an
                        existing withdrawal, the amount will be deducted from the total withdrawal amount. Free Bonuses
                        (Free Chips) have a wagering requirement of 35X to 100X unless otherwise specified
                    </li>
                    <li>6.10. All promotions offered by the Casino are only valid for the period specified in the
                        promotional offer itself which is posted on the Casino website and/or any other communication
                        provided by the Casino itself.
                    </li>
                    <li>6.11. A bonus equal to or greater than 200% that has been claimed with any deposit, will be
                        subject to a max cashout of 10x the deposit amount.
                    </li>
                    <li>6.12. A free bonus player (a player who plays only free bonuses, and didn't make a deposit yet)
                        can withdraw winnings when playing his first three free bonuses on his account, during the first
                        30 days after account registration After three bonuses played or after 30 days since
                        registration date, the funds received from a free bonus will be considered not cashable and will
                        be deducted from the balance or from the withdrawal.
                    </li>
                    <li>6.13. In case a player has finished \ played down, his wager requirement ,
                        the casino holds the right to adjust balance to the maximum withdrawal amount
                        allowed and clear any extra balance. In cases where a player is not eligible to
                        make a withdrawal , the casino holds the right to clear any winnings and balance once wager is
                        met.
                    </li>


                </ul>
            </>]

        },
        {
            title: "WITHDRAWAL TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>7.1. Please keep in mind that if you make a deposit of 249 USD/EURO/GBP/AUD or less, you can
                        only withdraw 10 times the amount of your deposit for that play session (all other currencies
                        are tied to USD). The casino will apply this rule to the withdrawal request and adjust the
                        withdrawal amount accordingly.
                        Any unused funds will be classified as "Non-Withdrawable" and subtracted from the player
                        account. Additionally, if a win is obtained when using a deposit bonus during a gaming session
                        and a withdrawal request is made, this win will also be regarded as a "Non-Withdrawable" amount
                        and will be subtracted from the particular pending withdrawal.
                    </li>
                    <li>7.2. Customers can submit their withdrawal request at any time. The approval time may take up to
                        12 working days starting the date authorization process was completed successfully. Processing a
                        pending withdrawal requires authorization from the Fraud department as well.
                    </li>
                    <li>7.3. Any and all bonuses redeemed by the player cannot be withdrawn or redeemed from the Casino,
                        and will be removed from the player's winnings upon processing of the withdrawal.
                    </li>
                    <li>7.4. All withdrawals are subject to a 10-40 $,€,£ transfer fee. Fees are based on location and
                        are determined by our payment processor and are the responsibility of the individual player to
                        absorb.
                    </li>
                    <li>7.5. For a withdrawal to be approved, the player will be asked to submit certain documentation
                        and information in order to verify the account. Failure to comply or failure to submit said
                        documents and information 30 days from the time the withdrawal was requested, will result in the
                        withdrawal being cancelled. It is the responsibility of the individual player to send the
                        required documentation. Withdrawal approval depends on all conditions outlined and the
                        verification of all required documents.
                    </li>
                    <li>7.6. A player may not withdraw winnings as long as there is still a wagering requirement
                        attached to their balance. They must complete the wagering requirements before making a
                        withdrawal request, or risk having the withdrawal denied.
                    </li>
                    <li>7.7. The casino has the right to refuse or withhold a bonus or winnings in the event of a
                        deposit being cancelled, returned or denied by any payment service or processor at any time.
                    </li>
                    <li>7.8. If the player has wagered on restricted games with a bonus that does not allow said games,
                        the Casino reserves the right to cancel any withdrawal and confiscate any winnings. Before
                        starting to play with a deposit bonus or free chip, it is the responsibility of the player to
                        check and understand what are the restrictions of that particular bonus or chip.
                    </li>
                    <li>7.9. The minimum amount for a withdrawal is 50 €/$/£/AUD or 500 ZAR.</li>
                    <li>7.10. Any and all deposits must be wagered at least once (1X) the sum of the deposit in order to
                        qualify for a withdrawal. A withdrawal cannot be approved unless a minimum wager of 1X the
                        deposit is first met. Deposits made as part of promotions are subject to that specific
                        promotion's Terms and Conditions and wagering requirement.
                    </li>
                    <li>7.11. By depositing funds into your account, you direct us and we agree to hold them, along with
                        any winnings, for the sole and specific purpose of using them (i) to place any gaming wagers;
                        and (ii) to settle any fees or charges that you might incur in connection with the use of our
                        services. We reserve the right to charge a fee of 3% - 6.5% for the processing of Credit Card
                        deposits.
                    </li>
                    <li>7.12. A player that didn't add funds to his account for one month-long or more (30 days), will
                        be considered a free bonus player and will not be eligible to withdraw winnings from free
                        bonuses or cash granted to him by the casino. The possibility of withdrawing winnings from free
                        bonuses is reserved for our active players only.
                    </li>
                    <li>7.13. Mixing a balance of a free-play with a balance of real-play is not allowed and is under
                        the player's responsibility. If a deposit was made on top of a balance from a free chip, any
                        playable balance the player had prior to depositing to play for real, will be considered free
                        play, therefore, under the rules of free play.
                    </li>
                </ul>
            </>]

        },
        {
            title: "PRIZES & WINNINGS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>8.1. Players who have their account verified , are allowed to withdraw up to €/£/AU$/$1000
                        (R10,000) per week . Incase no documents were sent yet and the account is not verified yet,
                        players will be permitted to withdraw up to €/£/AU$/$500 (R5000) per week . Our highest VIP
                        players are permitted to withdraw a maximum of €/£/AU$/$10,000 (R100,000) of their winnings per
                        12 day period at management's discretion.
                    </li>
                    <li>8.2. It is our goal to pay withdrawals within 1 and 12 business days. This time frame can vary
                        due to processor availability but we will always do everything in our power to pay our players
                        as fast as possible so you can get your winnings quickly and easily. During times of high cash
                        out levels, the casino reserves the right to lower the weekly withdrawal limits to €/£/AU$/$500.
                        Your withdrawal enters the withdrawal queue on the first business day after your verification
                        documents have been accepted.
                    </li>
                    <li>8.3. The casino reserves the right to request documentation for the purpose of identity
                        verification at any time; prior to granting any deposits or withdrawals, or upon suspicion of
                        fraudulent activity by the player. Accounts under fraud investigation may have their accounts
                        suspended and winnings frozen indefinitely until the investigation period is over. In the case
                        of suspected fraud, winnings emerging from credit/debit card deposits can be frozen and held for
                        up to 12 months.
                    </li>
                    <li>8.4. The casino reserves the right to reverse any withdrawal over 30 days old, due to missing
                        security documents or incomplete verification of the player account.
                    </li>
                    <li>8.5. Players winning a sizeable amount deemed to be worthy of publicity by the casino agree to
                        make themselves available for any event arranged by {casino.name} in relation to that
                        win. {casino.name}
                        will at all times respect the player’s privacy in this event.
                    </li>
                    <li>8.6. The casino reserves the right to use your first name and first initial of your surname
                        (e.g. John S.) in any casino announcement regarding winning results.
                        Any winnings accrued from a technical malfunction of the software or casino, will be voided and
                        removed from the player's account. Any deposit(s) involved will be refunded to the player's
                        account and may be cashed out for the original deposit(s) amount.
                    </li>
                    <li>8.7. The casino reserves the right to deny winnings and close account(s) if you have been
                        designated as a bonus abuser, playing no or low-risk strategies in order to withdraw money; if
                        you have not played at the Casino for personal entertainment only (i.e. if you systematically
                        employ betting systems to beat the casino, for example: Martingale); or if your personal
                        information is found blacklisted in our casino-industry-wide security database.
                    </li>
                    <li>8.8. Please be aware that only residents in the United Kingdom may opt for the British Pound
                        (GBP) currency. Winnings that occur in British Pounds will only be paid in the same currency to
                        legal residents within the UK.
                    </li>
                    <li>8.9. Winnings will be voided if player account has undergone chargeback(s) or unauthorized
                        refund(s) or is in any way linked to other account(s) where chargeback(s) have been placed
                        within the inter-casino security network.
                    </li>
                    <li>8.10. If a player deposits with a credit card he/she is required to have a security form
                        documents in order to process a cash-out, and there may be a waiting period of up to 7 days
                        before withdrawal is processed.
                    </li>
                    <li>8.11. Please note that players in the US, Australia, and UK can currently withdraw winnings
                        through Bitcoin only.
                    </li>
                    <li>8.12. When requesting a Bitcoin withdrawal, the player must enter their BTC wallet address in
                        the cashier. We will only pay to the BTC wallet registered at the time of requesting the
                        withdrawal. The casino will not be held responsible if a player enters an incorrect Bitcoin
                        address when requesting a BTC withdrawal. If we complete a BTC payment to a BTC address that
                        does not belong to the player (due to an error by the player when requesting the withdrawal), we
                        will not re-issue the payment.
                    </li>
                </ul>
            </>]

        },
        {
            title: "REFUND POLICY",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>9.1. Thanks for opening an account with us and depositing funds into your account at our
                        websites.
                    </li>
                    <li>We offer the option of a refund on all l deposits made on our websites. However, certain
                        conditions have to be met. We invite you to read the conditions explained in more detail below.
                    </li>
                    <li>Our returns policy is simple, straight forward, and we will be processed immediately.</li>
                    <li>However, there is a limited window of time as indicated:</li>
                    <ul>
                        <li>Within 24 hours of the original deposit.</li>
                        <li>As required at the time of opening the account, the compliance must be completed and
                            approved by our compliance department.
                        </li>
                        <li>Games malfunction - Screenshot required.</li>
                        <li>Unable to play credits - Balance unplayed.</li>
                        <li>Wrong charges - statement required.</li>
                        <li>Processing Fee</li>
                    </ul>
                    <li>There is a fee involved in processing and issuing your refund. The different fees (at your
                        currency) are as follows
                    </li>
                    <ul>
                        <li>Paper Check - 55.00</li>
                        <li>SEPA wire - 20.00</li>
                        <li>SWIFT wire - 75.00</li>
                    </ul>
                    <li>Late or missing refunds (if applicable):</li>
                    <ul>
                        <li>If you haven’t received your refund yet, first check your bank account again.</li>
                        <li>Then contact your credit card company, it may take some time before your refund is
                            officially posted.
                        </li>
                        <li>If you’ve done all this and you still have not received your refund yet, please contact us
                            at <a href={`mailto:${casino.support_email}`}>{casino.support_email}</a>
                        </li>
                    </ul>
                    
                    <li>Kindly note that for cryptocurrency deposits under $25, refunds cannot be facilitated as the transaction fees exceed the minimum refund threshold. In these instances, the deposited amount will be credited to the player's balance as credits, treated as a deposit, and subjected to the casino's terms and conditions applicable to all deposits.</li>
                </ul>
            </>]

        },
        {
            title: "PRIZES & WINNINGS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>10.1. Player's personal information that was used to register at the casino will be used for the
                        following purposes:
                    </li>
                    <ul>
                        <li>To determine the validity of player's identity and verifying activity under that name.</li>
                        <li>In providing information to a third party to collect any sums of money owed to the casino by
                            the player.
                        </li>
                        <li>When law requires, the reporting to the proper authorities for the detection and prevention
                            of crime.
                        </li>
                    </ul>

                </ul>
            </>]

        },
        {
            title: "MISUSE OF ACCOUNT",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>11.1. The casino reserves the right to suspend your account pending investigation in the event
                        of the casino having reasonable grounds for suspecting that you have been involved in any form
                        of collusion, syndication or fraud during your use of the casino platform, website and/or
                        systems. We also reserve the right to withhold the whole or part of your account balance, close
                        your account permanently, as well as recover from your account the amount of any winnings,
                        pay-outs or bonuses which have been affected by any of the following activities:
                    </li>
                    <li>11.2. Collusion: The joint effort of two or more individuals to gain an unfair advantage over
                        the software and other players via pooling of funds to achieve an unnatural increase of
                        available bankroll through multiple bonus awards (similarly to multi-accounting) to benefit/take
                        advantage from game engine design. This is usually done at a casino in order to increase the
                        total number of deposit bonuses awarded, thereby un-naturally maximizing probability of
                        achieving a large winning slot pay-out (for example: slot bonus round) while ensuring that the
                        pay-out of such an event is awarded to one of the colluding members, effectively detracting from
                        the winning potential of any other players using the casino.
                    </li>
                    <li>11.3. Syndication: We become aware that you are a member and/or form part of a syndicate, club
                        or group where players receive advantage play strategies in order to create positive expectancy
                        scenarios through the acquisition of promotional offers.
                    </li>
                    <li>11.4. Exploitation of a fault, loophole or error in our or any third party software provided for
                        the services of this agreement.
                    </li>
                    <li>11.5. We become aware that you have been found guilty of fraudulent or collusive behavior at
                        another online gaming business including charge-backs, collusion, or criminal activities.
                    </li>
                    <li>11.6. We become aware that you have charged back any or denied any of the transfers, deposits
                        and purchases to your account in a fraudulent manner.
                    </li>
                    <li>11.7. Make use of any automated play software such as "bots", or any type of simulation tools or
                        systems designed to provide an unfair advantage over the casino.
                    </li>
                    <li>11.8. As a player who makes use of this casino, you agree that you are not colluding or
                        attempting to collude, nor intending to participate directly or indirectly in a collusion scheme
                        which involves the sharing of winnings between selected players, sharing of funds, or working
                        together toward mutual end goal while benefitting from multiple acquisition incentive offers, to
                        the detriment of other players whom are not involved in the colluding party. You also agree that
                        you will inform the casino through one of the means necessary should you suspect a player to be
                        colluding, or planning to collude with other players. You also agree that you do not form part
                        of a syndicate or group that engages in advantage play strategies.
                    </li>
                    <li>11.9. We will thoroughly investigate any case in which we believe there may be any existence of
                        an element of fraudulent activity, collusion, syndication or illegal activity. We also reserve
                        the right to inform our business units, processing companies, electronic payment providers or
                        other financial institutions we use, of your identity and of any suspected unlawful, fraudulent
                        or improper activity.
                    </li>
                    <li>11.10. The following actions by the player will be considered a violation of the terms and
                        conditions of the Casino, but not limited to this list. Consequences may include termination of
                        the player's casino account, confiscation of winnings and existing balances, denial of services,
                        promotions and any other offers by the Casino.
                    </li>
                    <ul>
                        <li>More than one account at {casino.name} Online Casino and/or more than one account per
                            household and/or more than one account per device, and/or more than one account from the
                            same IP address, and/ or more than one account on an affiliated sister casino under the same
                            management as {casino.name} Online Casino.
                        </li>
                        <li>Should at any time, the casino discovers a linkage or connection to a fraudulent, suspended
                            or refunded or previously closed account on any brand within the Rival network or any other
                            online casino.
                        </li>
                        <li>Should at any time the casino discover an active or past complaint/dispute/criticism on a
                            public forum or blog or any other type of website.
                        </li>
                        <li>Should at any time the player abuse, harass or speak inappropriately to any of the Casino
                            staff, including verbal and written threats or intimidation.
                        </li>
                        <li>A mismatch on the Player's Casino account not matching the name on the credit card(s) or
                            payment method(s) used to make deposits on the account.
                        </li>
                        <li>Incorrect or false registration information.</li>
                        <li>The player is playing from a territory or region not fully authorized to allow online
                            gaming.
                        </li>
                        <li>The player is not of the required legal age according to the Casino Terms and Conditions.
                        </li>
                        <li>If the Player has allowed or permitted (intentionally or unintentionally) another player to
                            play on their own Casino account.
                        </li>
                        <li>If an individual has not played in the Casino purely as an entertainment and has only
                            participated in a professional capacity or in association with another player from another
                            club or group or part thereof.
                        </li>
                        <li>If the Player has been found deliberately making a 'chargeback' or 'dispute' of funds
                            deposited from their credit card, or any other payment method, into their Casino account; or
                            have been found to have made a 'chargeback' or threatened to do so in any other capacity on
                            a sister casino on the Rival platform or any other online casino or website. This includes
                            verbal and written threats to dispute, chargeback or stop transactions.
                        </li>
                        <li>If the Player is discovered to be cheating or has been found making irregular betting
                            patterns, or suspicious or irregular betting strategies and or the Player has instigated a
                            system (i.e. Martingale, machines, computers, software or any other form of automation) to
                            proliferate a cheat designed for the Casino to lose revenue due to this operation.
                        </li>
                        <li>The player is linked to any employee, ex-employee, company, third party or agency connected
                            to the Casino currently or in the past.
                        </li>
                        <li>The player has made any attempts to 'hack' the Casino software or website, or made any
                            changes to it in any way not specifically allowed by the Casino.
                        </li>
                    </ul>
                    <li>11.11 Players who choose to make a deposit using cryptocurrency must ensure that the token/coin
                        has successfully arrived and been confirmed on the blockchain to the designated crypto wallet
                        address provided during the deposit process. If a deposit is made to the wallet but subsequently
                        gets 'voided' or reversed, it will be regarded as fraudulent activity. Consequently, any
                        previous, ongoing, or future withdrawal requests made by the player will also be deemed void
                    </li>
                </ul>
            </>]

        },
        {
            title: "REFER-A-FRIEND TERMS AND CONDITIONS",
            content: [<>
                <ul style={{listStyle: 'none'}}>
                    <li>12.1. The general terms and conditions apply to the refer-a-friend program and all other
                        programs offered by {casino.name} Online Casino. By submitting all required information into the
                        refer-a-friend program, both you and the referred party (person being referred) consent to all
                        terms of this program and the referred party has consented to their data being supplied by you
                        to the referring party. By submitting this information, the referred party consents to having an
                        email sent to him/her by {casino.name} Online Casino requesting him/her to sign up for a real
                        player account. By submitting information into the refer-a-friend program, the referred party is
                        fully aware that the referring party has submitted their details in good faith.
                    </li>
                    <li>12.2. Persons utilizing the same computer (registering from the same IP address) will not be
                        permitted to partake in the refer-a-friend bonus for security purposes. Referred players must
                        not be existing members of {casino.name} Online Casino. The email address provided by the
                        referring party must not be associated with any current player of {casino.name} Online Casino
                        nor shall it be the same email as the referring member.
                    </li>
                    <li>12.3. The referring member will be required to fill out specific information about his/her
                        referred friend; this information includes, but is not limited to their given name, surname and
                        email address. After all required information has been provided, submitted and then verified
                        by {casino.name} Online Casino, then and only then shall the bonus be supplied to both the
                        referring and referred members.
                    </li>
                    <li>12.4. In order to qualify for the refer-a-friend bonus, the referred party must first sign up
                        for a real money account and deposit a minimum of 25 $,€,£ into that account. Both the referring
                        friend and the referred friend will receive their bonus only after the referred friend has
                        deposited 25 $,€,£ into their real player account and must allow for 48 hours before receiving
                        the bonus in their real player accounts.
                    </li>

                </ul>
            </>]

        },]
}
terms.en = untilWeDontHaveOther
// terms.de = untilWeDontHaveOther
// terms.it = untilWeDontHaveOther
// terms.fr = untilWeDontHaveOther
// terms.es = untilWeDontHaveOther

export const casino_contents = {
    about,
    aml,
    banking,
    bitcoin,
    bonus,
    cookie,
    faq,
    privacy,
    responsible,
    security,
    support,
    terms,
    vip,
}
let en_landings = {
    a: [
        {
            background: "url(/brand/images/bonus_images/landing.png) no-repeat center center / contain"
        },
    ],
    b: [
        {
            background: "url(/brand/images/bonus_images/landingb.png) no-repeat center center / contain"
        },
    ],
}

export const promos = {
    ////////////////////////////////////////////////////////////////////////////////
    landing_slider_data: {
        en: en_landings,
        fr: en_landings,
        it: en_landings,
        es: en_landings,
        de: en_landings,

    },
    bonus_slider_data: {
        en: [

            {
                background: "url(/brand/images/bonus_images/1.png) no-repeat center center / contain",
                button: {text: "Play now"}, image: {src: "/brand/images/bonus_images/1en.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.png) no-repeat center center / contain",
                button: {text: "Play now"}, image: {src: "/brand/images/bonus_images/1en.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/3.png) no-repeat center center / contain",
                button: {text: "Play now"}, image: {src: "/brand/images/bonus_images/1en.svg"}
            },


        ],

        de: [


            {
                background: "url(/brand/images/bonus_images/1.png) no-repeat center center / contain",
                button: {text: "JETZT SPIELEN"}, image: {src: "/brand/images/bonus_images/1de.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.png) no-repeat center center / contain",
                button: {text: "JETZT SPIELEN"}, image: {src: "/brand/images/bonus_images/1de.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/3.png) no-repeat center center / contain",
                button: {text: "JETZT SPIELEN"}, image: {src: "/brand/images/bonus_images/1de.svg"}
            },


        ],
        fr: [

            {
                background: "url(/brand/images/bonus_images/1.png) no-repeat center center / contain",
                button: {text: "JOUEZ MAINTENANT"}, image: {src: "/brand/images/bonus_images/1fr.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.png) no-repeat center center / contain",
                button: {text: "JOUEZ MAINTENANT"}, image: {src: "/brand/images/bonus_images/1fr.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/3.png) no-repeat center center / contain",
                button: {text: "JOUEZ MAINTENANT"}, image: {src: "/brand/images/bonus_images/1fr.svg"}
            },

        ],
        it: [


            {
                background: "url(/brand/images/bonus_images/1.png) no-repeat center center / contain",
                button: {text: "GIOCA ORA"}, image: {src: "/brand/images/bonus_images/1it.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.png) no-repeat center center / contain",
                button: {text: "GIOCA ORA"}, image: {src: "/brand/images/bonus_images/1it.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/3.png) no-repeat center center / contain",
                button: {text: "GIOCA ORA"}, image: {src: "/brand/images/bonus_images/1it.svg"}
            },


        ],
        es: [


            {
                background: "url(/brand/images/bonus_images/1.png) no-repeat center center / contain",
                button: {text: "JUEGUE YA"}, image: {src: "/brand/images/bonus_images/1es.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/2.png) no-repeat center center / contain",
                button: {text: "JUEGUE YA"}, image: {src: "/brand/images/bonus_images/1es.svg"}
            },
            {
                background: "url(/brand/images/bonus_images/3.png) no-repeat center center / contain",
                button: {text: "JUEGUE YA"}, image: {src: "/brand/images/bonus_images/1es.svg"}
            },


        ],

    },
    ////////////////////////////////////////////////////////////////////////////////
    promo_data: [
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },


            bonus_details: {
                en: "DEPOSIT BONUS",
                de: "EINZAHLUNGSBONUS",
                fr: "BONUS/DÉPÔT",
                es: "BONUS DE DEPÓSITO",
                it: 'BONUS DI DEPOSITO'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <><h2>Your first deposit will be matched with 200% Welcome bonus or 100% cashback insurance.</h2>
                    <p>Here at Casino Crazywinners you can play with two types of depositing incentives. Deposit bonuses
                        or Cashback Insurance.</p>
                    <p>Deposit bonuses are a bonus given to a player along with their deposit. Deposit bonuses are in
                        essence a match bonus. Whatever amount you deposit, the casino will match. The higher the
                        deposit amount, the higher your match bonus will be.</p>
                </>,
                de: <>
                    <p>Auf Ihre erste Einzahlung erhalten Sie einen 400% Willkommensbonus oder 100%
                        Cashback-Versicherung.</p>
                    <p>Hier bei Crazywinners haben Sie zwei Optionen, um mit Einzahlungsangeboten zu spielen. Entweder
                        mit einem Einzahlungsbonus oder mit einer Cashback-Versicherung.</p>
                    <p>Einzahungsboni sind Boni, die einem Spieler auf die Einzahlung angerechnet werden. Unabhängig
                        davon, welchen Betrag Sie einzahlen, wird die Spielbank die Prozente des Bonus direkt anrechnen.
                        Je höher der Einzahlungsbetrag, desto höher ist der gutgeschriebene Bonusbetrag.</p>
                    <p>Klicken Sie <a href="/terms">hier</a> für die AGB's des Willkommensbonus.</p> </>,
                fr: <><h2>Votre premier dépôt sera jumelé d'un Bonus de Bienvenue de 200% ou d'une assurance de 100%
                    cashback.</h2>
                    <p>Ici sur Casino Crazywinners vous pouvez jouer avec deux types de bonus. Bonus sur Dépôt ou
                        Assurance Cashback .</p>
                    <p>Les bonus sur dépôt sont un bonus donné à un joueur en fonction de son dépôt. Les bonus sur dépôt
                        sont essentiellement un pourcentage du dépôt. Quel que soit le montant déposé, le bonus
                        concordera. Plus vous déposez gros, plus gros le bonus sur dépôt sera.</p>
                    Cliquez <a href="/terms">ici</a> pour les termes et conditions de ce bonus </>,
                es: <>
                    <p>Su primer depósito será beneficiado con bono de Bienvenida de 400% o el 100% de reembolso de
                        dinero real.</p>
                    <p>Aquí en Casino Crazywinners, puedes jugar con dos tipos de beneficios. Bono por depósito o
                        seguros de reembolso de dinero</p>
                    <p>Bonos de depósito son un bono otorgado a un jugador junto con su depósito. Bonos de depósito son,
                        en esencia, un Bono Combinado. Cualquier cantidad que usted deposita, el casino igualar. Cuanto
                        mayor sea la cantidad del depósito, mayor será su bono .</p>
                    <p>Haga clic <a href="/terms">aquí</a> para ver los términos y condiciones de este bono.</p>  </>,
                it: <>
                    <p>Il primo deposito sara' abinato a un bonus del 200% o un'assicurazione del 100%</p>
                    <p>Qui al casino' Crazywinners puoi giocare con due tipi di incentivi di deposito. Deposito con
                        Bonus o Cashback assicurazione.</p>
                    <p>I bonus di deposito sono bonus dati al giocatore per incrementare il suo deposito. I bonus di
                        deposito sono essenzialmente bonus per giocare. Qualunque sia l'ammontare depositato, il casino'
                        lo aggiungera'. Piu' alto e' l'importo del deposito piu' alto sara' il tuo bonus.</p>
                    <p>Clicca <a href="/terms">qui</a>per i termini e le condizioni di questo bonus</p>
                </>,

            }
        },
        {

            name: {
                en: "Welcome Bonus",
                de: "WILLKOMMENSBONUS",
                es: "BONO DE BIENVENIDA",
                fr: "BONUS DE BIENVENUE",
                it: 'BONUS DI BENVENUTO',
            },

            bonus_details: {
                en: "CASHBACK INSURANCE",
                de: "CASHBACK-VERSICHERUNG",
                fr: "ASSURANCE CASHBACK",
                es: "CASHBACK SEGUROS",
                it: 'ASSICURAZIONE CASHBACK'
            },
            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Cashback insurance is just as it sounds. Insurance should the house win. Feel confident as you
                        play knowing that your money is guaranteed. Just speak with a support rep once your balance has
                        finished, and they will add your real cashback to your account.</p>
                </>,
                de: <>
                    <p>Die Cashback-Versicherung ist genau das, wonach es sich anhört. Sollte das Haus gewinnen, greift
                        die Versicherung. Sie brauchen sich beim Spielen keine Sorgen machen, denn Sie wissen, dass Ihr
                        Geld abgesichert ist. Sprechen Sie einfach mit einem Support-Mitarbeiter, sobald Ihr Guthaben
                        aufgebraucht ist, und er wird Ihnen die Cashback-Versicherung in der Kasse hinterlegen.</p>
                </>,
                fr: <>

                    <p>L'Assurance Cashback est exactement ce qu'elle parait. L'Assurance est créditée uniquement si la
                        banque remporte la mise. Soyez rassure en jouant car votre argent est garanti. Contactez
                        simplement un agent du support une fois que votre balance sera terminee afin qu'ils vous
                        creditent votre cashback reel a votre compte.</p>
                </>,
                es: <>
                    <p>Seguro de Cashback es como suena.El seguro es en caso de que la casa gane.Siéntase seguro
                        mientras que usted juega sabiendo que su dinero está garantizado. Sólo hable con un
                        representante de soporte una vez que su balance se alla terminado y ellos agregaran su cashback
                        en su cuenta.</p>
                </>,
                it: <>
                    <p>L'assicurazione cashback e' proprio come sembra. Assicurazione deve la vittoria alla casa.
                        Sentirsi sicuri nel giocare sapendo che i tuoi soldi sono garantiti. Parla solo con il supporto
                        se il tuo saldo finisce, e loro aggiungeranno nel tuo conto il cashback reale.</p>
                </>,
            }
        },
        {

            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "NEXT DAY CASHBACK",
                de: "TURNIERE",
                fr: "REMBOURSEMENT LE LENDEMAIN",
                es: 'TORNEOS',
                it: 'CASHBACK DEL GIORNO DOPO'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Discover the craziness of our next day cashback special. You get a guarantee of up to 30% on all
                        your deposits during the promotion period. The next day you get cash right back into your
                        account.</p>
                </>,

                de: <>
                    <p>Crazywinners hält jeden Tag neue Aktionen und Angebote für Sie bereit. Nehmen Sie an unseren
                        wöchentlichen Turnieren teil. Jeder Wetteinsatz, den Sie im Rahmen des Turniers machen, bringt
                        Ihnen einen Turnierpunkt ein. Diejenigen mit den meisten Turnierpunkten gewinnen einen Anteil
                        des 50000$ Gewinnpools.</p>
                </>,
                fr: <>
                    <p>Découvrez la folie de notre offre de remboursement du lendemain. Vous êtes garanti de recevoir 30
                        % de tous vos dépôts pendant la période de la promotion. Et le lendemain, vous récupérez
                        l'argent directement sur votre compte.</p>
                </>,
                es: <>
                    <p>Todos los días en el Casino Crazywinners hay una nueva promoción disponible.</p>
                    <p>Participa en nuestros torneos semanales donde cada apuesta que hagas gana un Punto de torneo y
                        los que tienga la mayor cantidad de puntos llegara a compartir el premio de 50K€</p>

                </>,
                it: <>
                    <p>Scopri la follia del nostro speciale cashback del giorno dopo. Ti garantiamo fino al 30% dei tuoi
                        depositi durante il periodo della promozione. Il giorno successivo, il credito arriva dritto sul
                        tuo account.</p>
                </>,
            }
        },
        {
            name: {
                en: "WEEKLY PROMOTIONS AND SPECIALS",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                fr: "PROMOTIONS ET OFFRES HEBDOMADAIRES",
                it: 'PROMOZIONI E SPECIALI SETTIMANALI',
            },

            bonus_details: {
                en: "VIP LOYALTY POINTS",
                de: "VERLOSUNG",
                fr: "POINTS DE FIDÉLITÉ VIP",
                es: 'RIFA',
                it: 'PUNTI FEDELTÀ VIP'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {
                en: <>
                    <p>Crazywinners specialises in treating its loyal players like royalty. That is why we have
                        introduced the COMP POINTS Rewards programme and it is automatically available to all Real Money
                        account holders. The more you play, the more COMP POINTS you collect. These points are
                        redeemable for CASH credits with no wagering requirements on them.</p>
                    <p>Start earning today!</p>
                    <p>For every bet you place at Crazywinners, you will earn points. Accumulate 1,000 comp-points and
                        you will be able to redeem it for €/₤/$ 1.</p>
                </>,

                de: <>
                    <p>Nehmen Sie an einer unserer wöchentlichen Verlosungen teil. Jede Einzahlung i.H.v. 25 erhält
                        automatisch ein Los. Je mehr Lose Sie haben, desto besser stehen die Chancen auf den Gewinn in
                        Cash.</p>
                </>,
                fr: <>
                    <p>Crazywinners est spécialiste pour traiter ses joueurs fidèles comme de la royauté. C’est pour
                        cette raison que nous avons introduit le programme des POINTS DE FIDÉLITÉ et il est
                        automatiquement disponible à tous les propriétaires de compte en argent réel. Plus vous jouez,
                        plus vous récupérez de POINTS DE FIDÉLITÉ. Ces points sont ensuite échangeables contre des
                        crédits en ESPÈCE sans condition de mise.</p>
                    <p>Commencez à gagner dès aujourd’hui !</p>
                    <p>Vous gagnez des points pour chaque mise que vous placez sur Crazywinners. Accumulez 1 000 points
                        de fidélité et pourrez récupérer 1 €/₤/$.</p>
                </>,
                es: <>
                    <p>Únete a uno de nuestros sorteos semanales. Cada depósito del 25€ gana un boleto para la rifa.
                        Mientras más boletos ganan, mejores serán sus posibilidades de ganancias un premio en
                        efectivo.</p>
                </>,
                it: <>
                    <p>Crazywinners è specializzato nel trattare i suoi giocatori più fedeli come dei re. Per questo
                        abbiamo introdotto il programma di ricompensa con i PUNTI FEDELTÀ disponibile in automatico per
                        tutti gli account in Denaro Reale. Più giochi, più PUNTI FEDELTÀ raccogli. Questi punti sono
                        riscattabili in CREDITO e non sono soggetti a requisiti di puntata.
                    </p>
                    <p>Inizia a guadagnare oggi!</p>
                    <p>Per ogni puntata che effettui su Crazywinners, riceverai punti. Accumula 1.000 punti fedeltà e
                        potrai riscattarli con €/₤/$ 1.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "CASHPARTY",
                es: 'CASHTRAVAGANZA'
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Entdecken Sie die Vorzüge unseres Cashparty-Specials. Dabei erhalten Sie eine Garantie von bis zu
                        80% auf all Ihre Einzahlungen während des Cashparty-Zeitraums. Am nächsten Tag erhalten Sie Ihr
                        Bargeld direkt zurück in Ihre Kasse.</p>
                </>,
                es: <>
                    <p>Descubra la locura de nuestro especial Cashtravaganza. Usted obtiene una garantía de hasta un 80%
                        en todos sus depósitos durante el período Cashtravaganza. Al día siguiente usted consigue el
                        efectivo en su cuenta.</p>
                </>,
            }
        },
        {
            name: {
                es: "PROMOCIONES Y OFERTAS SEMANALES",
                de: "WÖCHENTLICHE AKTIONEN UND SPECIALS",
            },

            bonus_details: {
                de: "TREUEPUNKTE",
                es: "COMP POINTS",
            },

            details_url: "",
            img: '/brand/images/promotions_images/ballpromo.png',
            details: {

                de: <>
                    <p>Holen Sie sich Ihre Treuepunkte. Alle einzahlenden und berechtigten Spieler können Ihre
                        Treuepunkte auf gewettetes Echtgeld anrechnen lassen. Die Treuepunkt-Rate beträgt 1Punkt pro 1€
                        Einsatz, wobei 100.000 Comp-Punkte in 100€ umgewandelt werden können.</p>
                </>,
                es: <>
                    <p>Consiga sus puntos de bonificación. Todos los depósitos y los jugadores elegibles y reclamar sus
                        puntos de bonificación en dinero real apostado. La tasa de punto de un borrador es 1 punto comp
                        por cada €1 apostado en 100.000 puntos de bonus pueden ser convertidos en €100.</p>
                </>,
            }
        },

    ],
    ////////////////////////////////////////////////////////////////////////////////
    jackpot: [
        {
            name: "Jim S. won",
            value: "$11,910.14",
            desc: "on at the Strike Gold "
        },
        {
            name: "Eve G. won",
            value: "$1.733.00",
            desc: "on at the copa "
        },
        {
            name: "Jordan W. won ",
            value: "$5,235.60",
            desc: "on at the Major Moolah "
        },
        {
            name: "John H. won",
            value: "$856.00",
            desc: "on at the Catsino "
        },
        {
            name: "Pam T. won ",
            value: "$29,990.58",
            desc: "on at the Money Magic"
        },
    ]
    ////////////////////////////////////////////////////////////////////////////////
}

Object.keys(casino_contents.about).forEach((key) => {
    // remove first 2 items from the array, leaving the rest
    casino_contents.about[key].sections[0].content = casino_contents.about[key].sections[0].content.slice(2)
})


export default casino_contents





